import waitUntil from 'lib/wait-until';
import { Action } from 'stores/index';
import Web3Service, { Web3Status, ProviderName } from 'services/web3';
import PhemeService from 'services/pheme';
import EndorsementsService from 'services/endorsements';

import { syncTransactionChanges } from 'stores/transaction';
import { syncUserChanges } from 'stores/user';

import * as ethers from 'ethers';
export const APP_STATUS_UPDATE = 'app/statusUpdate';

const IPFS_RPC = 'https://ipfs.infura.io:5001';
const IPFS_GATEWAY = 'https://ipfs.infura.io';

export type State = {
  status: Web3Status,
  providerName: ProviderName,
  isReadOnly: boolean,
  networkId: number,
  blockNumber: number,
};

function getDefaultState(): State {
  return {
    status: 'BOOTING',
    providerName: undefined,
    isReadOnly: true,
    networkId: undefined,
    blockNumber: undefined,
  };
};

export default function reducer(state: State = getDefaultState(), action: any): State {
  switch (action.type) {
    case APP_STATUS_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sync = (): Action => async (dispatch, getState) => {
  const web3 = Web3Service.getInstance();
  const pheme = PhemeService.getInstance();

  const { status } = web3;

  const [
    networkId,
    blockNumber,
  ] = await (Promise.all([
    web3.provider.getNetwork().then(network => network.chainId),
    web3.provider.getBlockNumber(),
  ] as Array<any>) as Promise<[string, ethers.utils.BigNumber, string, number]>);

  dispatch({ type: APP_STATUS_UPDATE, payload: {
    blockNumber,
    status,
    networkId,
    providerName: web3.providerName,
    isReadOnly: web3.isReadOnly
  }});
}

export const initialize = (): Action => async (dispatch, getState) => {
  try {
    const web3 = await Web3Service.initialize();
    await PhemeService.initialize(web3, IPFS_RPC, IPFS_GATEWAY);
    await EndorsementsService.initialize(web3);

    const syncFunction = async () => {
      await dispatch(syncUserChanges());
      await dispatch(sync());
      await dispatch(syncTransactionChanges());
    }

    await syncFunction();
    Web3Service.getInstance().provider.on('block', syncFunction);
  } catch (error) {
    dispatch({ type: APP_STATUS_UPDATE, payload: { status: 'FAILED' } });
    throw error;
  }
}